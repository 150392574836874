import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffcfc;
`;

export const Content = styled.div`
  width: 100%;
  padding: 20px;
  max-width: 360px;
  display:flex;
  flex-direction:column;
`;

export const Logo = styled.img`
    width:50%;
    display:block;
    margin:  0 auto 20px;
`
import { Modal, Spin, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import api from "../../services/api";
import { formatDateTime } from "../../services/date";
import DefaultButton from "../Button";
import { ContainerButton, Image, SpinContainer, Text } from "./style";

function PointDetailModal(props, ref) {
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);
  const [pointChangeLoading, setPointChangeLoading] = useState(false);
  const [receiptLoading, setReceiptLoading] = useState(false);
  const [activeChangePoint, setActiveChangePoint] = useState(false);
  const [text, setText] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      show: (itemSelected) => {
        setVisible(true);
        onUpdatePoint(itemSelected);
      },
    }),
    []
  );

  async function onUpdatePoint(itemSelected = null) {
    try {
      setItem(null);
      let data = itemSelected == null ? item : itemSelected;

      const {
        data: { response },
      } = await api.get(`pointRegister/${data.id}`);
      setItem(response);
    } catch (error) {
      setTimeout(() => onUpdatePoint(itemSelected), 2000);
    }
  }

  async function handleReceipt() {
    try {
      setReceiptLoading(true);
      await api.post(`pointRegister/${item.id}/receipt`);
      setReceiptLoading(false);

      message.success("Seu comprovante foi enviado por e-mail");
    } catch (error) {
     
      message.info("Ocorreu um erro ao enviar seu comprovante");
    }
  }

  async function handlePointChange() {
    setActiveChangePoint(true);
  }

  async function handleSendPointChange() {
    setPointChangeLoading(true);
    api
      .post(`pointChange`, {
        point_register_id: item.id,
        employee_id: item.employee_id,
        company_id: item.company_id,
        obs: text,
      })
      .then(() => {
        setPointChangeLoading(false);
        setActiveChangePoint(false);
        setText("");
        onUpdatePoint(item);
      })
      .catch(() => {
        setPointChangeLoading(false);
      });
  }

  const handleCancel = useCallback(() => {
    if (activeChangePoint) return setActiveChangePoint(false);
    setVisible(false);
    setItem(null);
  }, [activeChangePoint]);

  return (
    <Modal
      visible={visible}
      centered
      title={activeChangePoint ? "Solicitar alteração" : "Detalhes do ponto"}
      footer={
        <ContainerButton>
          {activeChangePoint
            ? [
              <DefaultButton
                loading={pointChangeLoading}
                onClick={handleSendPointChange}
              >
                Enviar Solicitação
                </DefaultButton>,
            ]
            : [
              item != null &&
              (item.point_change == null ||
                item.point_change.status === "Recusado") && (
                <DefaultButton onClick={handlePointChange}>
                  Alteração Ponto
                </DefaultButton>
              ),
              <DefaultButton loading={receiptLoading} onClick={handleReceipt}>
                Enviar Comprovante
                </DefaultButton>,
            ]}
        </ContainerButton>
      }
      onCancel={handleCancel}
    >
      {!activeChangePoint && (
        <div>
          <SpinContainer>{!item && <Spin />}</SpinContainer>
          {item != null && <Image src={item.selfie} />}

          <Text>
            {item != null &&
              `Horário de registro: ${formatDateTime(item.schedule)}`}
          </Text>
          {item != null && (
            <Text>
              Tipo:{" "}
              <span style={{ textTransform: "uppercase" }}> {item.type}</span>
            </Text>
          )}
          {item != null && item.address != null && (
            <Text>Endereço: {item.address}</Text>
          )}
          {item != null && item.point_change != null && (
            <Text>Solicitação de alteração: {item.point_change.status}</Text>
          )}
          {item != null && item.point_change != null && (
            <Text>Observação: {item.point_change.obs}</Text>
          )}
        </div>
      )}

      {activeChangePoint && (
        <div>
          <TextArea
            value={text}
            onChange={(event) => {
              setText(event.target.value);
            }}
            rows={4}
            placeholder="Digite sua observação"
          />
        </div>
      )}
    </Modal>
  );
}

export default forwardRef(PointDetailModal);

import styled from "styled-components";

export const Container = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#444;
    & > .camera  {
        width:100%;
        margin: 10px 0 20px;
    }
    & > button:not(.single){
        margin:0 10px;
        display:block;
        width:calc(100% - 20px);
    }
`;

export const ButtonGroupContainer = styled.div`
    display:flex;
    justify-content:space-between;
    & > button{
        margin:0 10px;
    }
`

export const Image = styled.img`
    margin: 10px 0 20px;
    width:100%;
`;
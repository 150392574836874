import {
  AimOutlined,
  CheckCircleOutlined,
  FieldTimeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Card, List } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ContainerLayout from "../../components/ContainerLayout";
import PointDetailModal from "../../components/PointDetailModal";
import { SAIDA } from "../../constant/type";
import { useAuth } from "../../contexts/auth";
import api from "../../services/api";
import { formatDateTime } from "../../services/date";

export default function Historic() {
  const modalRef = useRef();
  const { user } = useAuth();
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(null);

  useEffect(() => {
    getPoints(1, true);
  }, []);

  async function getPoints(pageNumber = page, shouldRefresh = false) {
    try {
      if (pageNumber > totalPage && !shouldRefresh) return;
      if (loading) return;

      setLoading(true);

      const {
        data: { response },
      } = await api.get(
        `company/${user.employee.company_id}/pointRegister/historic?page=${pageNumber}`
      );

      setLoading(false);
      setTotalPage(response.last_page);
      setPage(pageNumber + 1);

      setList(!shouldRefresh ? [...list, ...response.data] : response.data);
    } catch (error) {
      setLoading(false);
    }
  }

  const handlePointDetail = useCallback((point) => {
    modalRef.current.show(point);
  }, []);

  return (
    <ContainerLayout>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        dataSource={list}
        loading={loading}
        renderItem={(point) => (
          <List.Item>
            <Card
              title={
                <>
                  <CheckCircleOutlined
                    style={{
                      color: point.type === SAIDA ? "#fa5a5a" : "#388e3c",
                    }}
                  />{" "}
                  {point.type.toUpperCase()}
                </>
              }
              extra={
                <a href="#" style={{ color: "#fa5a5a", fontSize: "12pt" }} onClick={(event) => {
                  event.preventDefault();
                  handlePointDetail(point);
                }}>
                  <MoreOutlined />
                </a>
              }
            >
              <p>
                <FieldTimeOutlined />{" "}
                {formatDateTime(point.schedule)}
              </p>
              <p>
                <AimOutlined /> {point.address}
              </p>
            </Card>
          </List.Item>
        )}
      />
      <PointDetailModal ref={modalRef} />
    </ContainerLayout>
  );
}

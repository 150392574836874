import React, { useCallback, useState } from "react";
import { Alert, Form, Input } from "antd";
import DefaultButton from "../Button";
import api from "../../services/api";
import { useAuth } from "../../contexts/auth";

export default function SecurityStep({ document, companyId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { saveAuthToken } = useAuth();

  const handleLogin = useCallback(async (values) => {
    setLoading(true);

    const { password } = values;

    api
      .post("auth/login", {
        document,
        company_id: companyId,
        password,
      })
      .then(({ data }) => {
        saveAuthToken(data);
      })
      .catch(() => {
        setError("Senha inválida");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Form onFinish={handleLogin}>
      <Form.Item
        label="Senha"
        name="password"
        rules={[{ required: true, message: "Informe a senha!" }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <DefaultButton loading={loading} type="primary" htmlType="submit" block>
          Próximo
        </DefaultButton>
      </Form.Item>
      {error != null && <Alert message={error} type="error" showIcon />}
    </Form>
  );
}

import React, { useState } from "react";

import { Avatar, Drawer, Layout, Menu } from "antd";
import {
  FieldTimeOutlined,
  HistoryOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ContentHeader, HeaderStyled, LayoutStyled } from "./style";
import DefaultButton from "../Button";
import { useAuth } from "../../contexts/auth";
import { useHistory } from "react-router-dom";

const { Content } = Layout;

export default function ContainerLayout({ children , style }) {
  const { logout } = useAuth();
  const [visible, setVisible] = useState(false);

  const history = useHistory();

  return (
    <LayoutStyled className="default-container">
      <Layout>
        <HeaderStyled className="site-layout-sub-header-background">
          <DefaultButton onClick={() => setVisible(true)}>
            <MenuOutlined />
          </DefaultButton>
        </HeaderStyled>
        <Drawer
          title={<HeaderDrawer />}
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          placement="left"
          className="menu-default"
        >
          <Menu mode="inline" defaultSelectedKeys={[window.location.pathname]}>
            <Menu.Item
              key="/"
              icon={<HomeOutlined />}
              onClick={() => history.push("/")}
            >
              Início
            </Menu.Item>
            <Menu.Item
              key="/historic"
              icon={<HistoryOutlined />}
              onClick={() => history.push("/historic")}
            >
              Histórico
            </Menu.Item>
            <Menu.Item
              key="/request"
              icon={<FieldTimeOutlined />}
              onClick={() => history.push("/request")}
            >
              Solicitações
            </Menu.Item>
            <Menu.Item
              key="/settings"
              icon={<SettingOutlined />}
              onClick={() => history.push("/settings")}
            >
              Configurações
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                logout();
                history.push("/");
              }}
              key="5"
              icon={<LogoutOutlined />}
            >
              Sair
            </Menu.Item>
          </Menu>
        </Drawer>
        <Content style={{ margin: "24px 0 0", overflowY: "auto" , ...style}}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 ,  ...style}}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </LayoutStyled>
  );
}

function HeaderDrawer() {
  const { user } = useAuth();

  if (user == null) return null;

  return (
    <ContentHeader>
      <Avatar size={64} icon={<UserOutlined />} src={user.image} />
      <p>{user != null && user.name}</p>
      <label>{user != null && user.employee.company_office.name}</label>
    </ContentHeader>
  );
}

import { format, addMinutes } from 'date-fns';

export const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return format(addMinutes(date, date.getTimezoneOffset()), "dd/MM/yyyy HH:mm");
};

export const formatTime = (dateString) => {
    const date = new Date(dateString);
    return format(addMinutes(date, date.getTimezoneOffset()), "HH:mm:ss");
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(addMinutes(date, date.getTimezoneOffset()), "dd/MM/yyyy");
};


import styled from "styled-components";

export const ProfileContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
`;

export const NameText = styled.p`
    font-size:13pt;
    text-align:center;
    margin:0;
`;

export const ContainerOption = styled.div`
    display:flex;
    align-items:center;
    & > div {
        margin-left:20px;
    }
`;

export const TitleOption = styled.p`
    margin:0;
`;

export const LabelOption = styled.label`
    color:#444;
    font-size:10pt;
`;
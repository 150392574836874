import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, List } from "antd";
import React, { useRef } from "react";
import ChangePasswordModal from "../../components/ChangePasswordModal";
import ContainerLayout from "../../components/ContainerLayout";
import { useAuth } from "../../contexts/auth";
import {
  ContainerOption,
  LabelOption,
  NameText,
  ProfileContainer,
  TitleOption,
} from "./style";

export default function Settings() {
  const { user } = useAuth();
  const modalRef = useRef();

  const options = [
    {
      title: "Alteração de senha",
      description: "Troca de senha da conta",
      icon: <KeyOutlined />,
      onCallback: () => modalRef.current.show(),
    },
  ];

  return (
    <ContainerLayout style={{ padding: 0 }}>
      <ProfileContainer>
        <Avatar size={100} icon={<UserOutlined />} src={user.image} />
        <div>
          <NameText>{user.name}</NameText>
          <p>{user.employee.company_sector.name}</p>
        </div>
      </ProfileContainer>
      <List
        bordered
        dataSource={options}
        renderItem={(item) => (
          <List.Item onClick={item.onCallback}>
            <ContainerOption>
              {item.icon}
              <div>
                <TitleOption>{item.title}</TitleOption>
                <LabelOption>{item.description}</LabelOption>
              </div>
            </ContainerOption>
          </List.Item>
        )}
      />
      <ChangePasswordModal ref={modalRef} />
    </ContainerLayout>
  );
}

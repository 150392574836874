import React from "react";
import ReactInputMask from "react-input-mask";
import { InputStyled } from "./style";

const InputMask = (props) => {
  return (
    <ReactInputMask {...props}>
      {(inputProps) => (
        <InputStyled
          {...inputProps}
          disabled={props.disabled ? props.disabled : null}
        />
      )}
    </ReactInputMask>
  );
};

export default InputMask;

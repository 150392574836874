import styled from "styled-components";
import { Button } from "antd";

const DefaultButton = styled(Button)`
  background-color: #f95a5a;
  border-color: #f95a5a;
  color:#fff!important;
  &:hover,
  &:focus {
    background-color: #ff7777;
    border-color: #ff7777;
  }
`;

export default DefaultButton;

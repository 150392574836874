import React from "react";

import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import CapturePicture from "../pages/CapturePicture";
import Home from "../pages/Home";
import Splash from "../pages/Splash";
import {useAuth} from "../contexts/auth";
import Historic from "../pages/Historic";
import PointRequest from "../pages/PointRequest";
import Settings from "../pages/Settings";

export default function AppRoutes() {
  const {user} = useAuth();

  if(!user)
    return <Splash />

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/capture" component={CapturePicture} />
        <Route exact path="/historic" component={Historic} />
        <Route exact path="/request" component={PointRequest} />
        <Route exact path="/settings" component={Settings} />
      </Switch>
    </Router>
  );
}

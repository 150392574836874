import React, { useCallback, useState } from "react";
import { Alert, Form } from "antd";
import InputMask from "../../components/InputMask";
import DefaultButton from "../Button";
import api from "../../services/api";

export default function DocumentStep({onNextStep}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFindDocument = useCallback(async (values) => {
    const document = values.cpf.match(/\d+/g).join("");

    setLoading(true);

    const {
      data: { response },
    } = await api.get(`user/${document}/companies`);

    setLoading(false);

    if (response.length === 0) return setError("Usuário não encontrado");

    setError(null);

    onNextStep({document, data:response})
  }, []);

  return (
    <Form onFinish={handleFindDocument}>
      <Form.Item
        label="CPF"
        name="cpf"
        rules={[{ required: true, message: "Informe o seu cpf!" }]}
      >
        <InputMask
          type="tel"
          mask="999.999.999-99"
          placeholder="###.###.###-##"
        />
      </Form.Item>
      <Form.Item>
        <DefaultButton loading={loading} type="primary" htmlType="submit" block>
          Próximo
        </DefaultButton>
      </Form.Item>
      {error != null && <Alert message={error} type="error" showIcon />}
    </Form>
  );
}

import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../services/api";

const AuthContext = createContext({
  signed: true,
  loading: true,
  authToken: {},
  user: {},
  config: {},
  saveAuthToken: (data) => { },
  logout: () => { },
  getDataUser: () => { },
  getConfig: () => { },
  updateDataUser: () => { },
});

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    async function loadStorageData() {
      const storageAuth = localStorage.getItem("@Auth:token");
      const storageUser = localStorage.getItem("@Auth:user");

      if (storageAuth) {
        const data = JSON.parse(storageAuth);

        api.defaults.headers["Authorization"] = `Bearer ${data.token}`;

        if (storageUser) {
          setUser(JSON.parse(storageUser));
        }

        setAuthToken(data);
        configApiInterceptor();
      }

      setLoading(false);
    }

    loadStorageData();
  }, []);

  function configApiInterceptor() {
    api.interceptors.response.use(undefined, async (err) => {
      const error = err.response;

      console.log("error");

      if (error.status === 401) {
        if (error.config.url === "auth/refresh") {
          logout();
        } else {
          const response = await refreshToken();
          return response;
        }
      }

      return Promise.reject(error);
    });
  }

  async function refreshToken() {
    const storageUser = localStorage.getItem("@Auth:user");

    if (storageUser === null) {
      logout();
      return;
    }

    const userData = JSON.parse(storageUser);

    return api
      .put(`auth/refresh`, {
        company_id: userData.employee.company_id,
        user_id: userData.id,
      })
      .then(({ data }) => {
        saveAuthToken(data);
        return Promise.resolve(data);
      })
      .catch(() => {
        logout();
        return Promise.reject();
      });
  }

  async function getDataUser() {
    const {
      data: { response },
    } = await api.get("auth/me");
    setUser(response);
    localStorage.setItem("@Auth:user", JSON.stringify(response));
  }

  async function updateDataUser(data) {
    setUser(data);
    localStorage.setItem("@Auth:user", JSON.stringify(data));
  }

  async function getConfig() {
    if (user) {
      const {
        data: { response },
      } = await api.get(
        `company/${user.employee.company_id}/pointRegister/config`
      );
      setConfig(response);
    }
  }

  async function saveAuthToken(data) {
    localStorage.setItem("@Auth:token", JSON.stringify(data));
    setAuthToken(data);
    api.defaults.headers["Authorization"] = `Bearer ${data.token}`;
    /*api.interceptors.request.use(
      (config) => {
        config.headers["Authorization"] = `Bearer ${data.token}`;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );*/

    setTimeout(() => {
      configApiInterceptor();
    }, 2000);
  }

  async function logout() {
    localStorage.clear();
    setAuthToken(null);
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!authToken,
        loading,
        user,
        config,
        authToken,
        saveAuthToken,
        logout,
        getDataUser,
        getConfig,
        updateDataUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

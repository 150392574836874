import styled from "styled-components";


export const Image = styled.img`
    width:100%;
    margin:0 0 10px;
`;

export const Text = styled.p`
    margin-bottom: 5px;
`;

export const SpinContainer = styled.div`
    display:flex;
    justify-content:center;
`;

export const ContainerButton = styled.div`
    display:flex;
    justify-content:flex-end;
`;
import styled from "styled-components";
import { Layout } from "antd";

export const LayoutStyled = styled(Layout)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const HeaderStyled = styled(Layout.Header)`
  background-color: #f73838;
  padding: 0 10px;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span{
      margin-bottom:10px;
  }
  & >  p{
    font-size:10pt;
    margin:0;
  }
  & >  label{
    font-size:8pt;
    margin:0;
  }
`;

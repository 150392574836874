import { Card, List } from "antd";
import React, { useEffect, useState } from "react";
import ContainerLayout from "../../components/ContainerLayout";
import api from "../../services/api";
import { formatTime, formatDate } from "../../services/date";

export default function PointRequest() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPointsRequest();
  }, []);

  async function getPointsRequest() {
    setLoading(true);
    const {
      data: { response },
    } = await api.get(`/pointChange`);

    setList(response.data);
    setLoading(false);
  }

  return (
    <ContainerLayout>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        dataSource={list}
        loading={loading}
        renderItem={(data) => (
          <List.Item>
            <Card
              title={`Data: ${formatDate(
                data.old_schedule
              )}`}
              extra={`Status: ${data.status}`}
            >
              <div>
                <p
                  style={{
                    fontSize: 14,
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                >
                  Ponto Atual:
                </p>
              </div>

              <p
                style={{
                  marginLeft: 21,
                  fontSize: 14,
                  textTransform: "capitalize",
                }}
              >
                {data.point_register.type}{" "}
                {formatTime(data.old_schedule)}
              </p>

              <div>
                <p
                  style={{
                    fontSize: 14,
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                >
                  Ponto Alterado:
                </p>
              </div>

              <div
                style={{
                  marginLeft: 21,
                  fontSize: 14,
                  textTransform: "capitalize",
                }}
              >
                {data.schedule !== null
                  ? `${data.point_register.type} ${formatTime(
                      data.point_register.schedule
                    )}`
                  : "Aguardando Análise"}
              </div>

              <p
                style={{
                  fontSize: 14,
                  marginTop: 10,
                  color: "#fa5a5a",
                  textTransform: "capitalize",
                }}
              >
                {data.obs === null ? "" : `Observação: ${data.obs}`}
              </p>
            </Card>
          </List.Item>
        )}
      />
    </ContainerLayout>
  );
}

import React, { useCallback, useState } from "react";
import { Container, Content, Logo } from "./style";
import pathLogo from "../../assets/logo.png";
import DocumentStep from "../../components/DocumentStep";
import CompanyStep from "../../components/CompanyStep";
import SecurityStep from "../../components/SecurityStep";

export default function SignIn() {
  const [step, setStep] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [doc, setDoc] = useState("");

  const RenderStep = useCallback(() => {
    switch (step) {
      case 1: {
        return (
          <DocumentStep
            onNextStep={({ document, data }) => {
              setDoc(document);
              setCompanies(data);
              setStep(2);
            }}
          />
        );
      }
      case 2: {
        return (
          <CompanyStep
            companies={companies}
            onNextStep={(data) => {
              setCompanyId(data);
              setStep(3);
            }}
          />
        );
      }
      case 3: {
        return <SecurityStep document={doc} companyId={companyId} />;
      }
      default: {
        return null;
      }
    }
  }, [step, companies, doc]);

  return (
    <Container>
      <Content>
        <Logo src={pathLogo} />
        <RenderStep />
      </Content>
    </Container>
  );
}

import {
  ArrowLeftOutlined,
  CameraOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Webcam from "react-webcam";
import DefaultButton from "../../components/Button";
import { useAuth } from "../../contexts/auth";
import api from "../../services/api";
import { ButtonGroupContainer, Container, Image } from "./style";

export default function CapturePicture() {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const webcamRef = useRef();

  const history = useHistory();

  const { user } = useAuth();

  const capture = useCallback(() => {
    setImage(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  const handleSend = useCallback((isTryAgain = false) => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(function (position) {
      const file = dataURLtoFile(image, "image.jpg");

      const formData = new FormData();

      formData.append("selfie", file);
      formData.append("employee_id", user.employee.id);
      formData.append("latitude", position.coords.latitude);
      formData.append("longitude", position.coords.longitude);
      formData.append("device_model", "Web");
      formData.append("app_version", "-");

      api
        .post(
          `company/${user.employee.company_id}/pointRegister/mark`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data: { response: { id } } }) => {
          message.success("Ponto registrado com sucesso!", 5);
          history.push("/");
        })
        .catch(() => {
          if (isTryAgain) {
            setLoading(false);
            message.error("Ocorreu um erro ao bater o ponto");
          } else {
            setTimeout(() => {
              handleSend(true);
            }, 1000)
          }
        });
    }, function(){
      message.error("Não foi possível obter sua localização");
      setLoading(false);
    });
  }, [image]);

  const handleClose = useCallback(() => {
    setImage(null);
  }, [image])

  const goBack = useCallback(() => {
    history.push("/");
  }, []);

  return (
    <Container>
      <DefaultButton
        className="single"
        icon={<ArrowLeftOutlined />}
        onClick={goBack}
      />
      {!image && (
        <Webcam
          videoConstraints={{ facingMode: "user" }}
          className="camera"
          audio={false}
          ref={webcamRef}
          mirrored={true}
          screenshotFormat="image/jpeg"
        />
      )}
      {image && <Image src={image} />}
      {!image && (
        <DefaultButton block icon={<CameraOutlined />} onClick={capture} />
      )}
      {image && (
        <ButtonGroupContainer>
          <DefaultButton
            loading={loading}
            block
            icon={<CheckOutlined />}
            onClick={()=>handleSend()}
          />
          <DefaultButton block icon={<CloseOutlined />} onClick={handleClose} hidden={loading} />
        </ButtonGroupContainer>
      )}
    </Container>
  );
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

import React, { useCallback, useEffect } from "react";
import { Form, Select } from "antd";
import DefaultButton from "../Button";

export default function CompanyStep({ companies, onNextStep }) {
  const handleSelectedCompany = useCallback(async (values) => {
    onNextStep(values.company);
  }, []);

  const [form] = Form.useForm();

  useEffect(() => {
    if (companies[0] != null) form.setFieldsValue({ company: companies[0].id });
  }, []);

  return (
    <Form form={form} onFinish={handleSelectedCompany}>
      <Form.Item
        label="Selecione uma empresa"
        name="company"
        rules={[{ required: true, message: "Informe a empresa" }]}
      >
        <Select>
          {companies.map((company) => {
            return (
              <Select.Option key={company.id} value={company.id}>
                {company.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item>
        <DefaultButton type="primary" htmlType="submit" block>
          Próximo
        </DefaultButton>
      </Form.Item>
    </Form>
  );
}

import React, { useEffect } from "react";
import { Container } from "./style";
import { Spin } from "antd";
import { useAuth } from "../../contexts/auth";

export default function Splash() {
  const { getDataUser } = useAuth();

  useEffect(() => {
    getDataUser();
  }, []);

  return (
    <Container>
      <Spin size="large" />
    </Container>
  );
}

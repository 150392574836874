import { Input, message, Modal } from "antd";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import api from "../../services/api";
import DefaultButton from "../Button";

function ChangePasswordModal(props, ref) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        setVisible(true);
      },
    }),
    []
  );

  const handleChangePassword = useCallback(() => {
    if (currentPassword.length === 0)
      return message.info("Informe a senha atual");
    if (newPassword.length < 6)
      return message.info("A nova senha deve ter mais de 5 caracteres");

    if (newPassword !== confirmPassword)
      return message.info("As senhas não conferem");

    setLoading(true);

    api
      .put(`user/password`, {
        password: currentPassword,
        new_password: newPassword,
      })
      .then(({ data }) => {
        setLoading(false);
        message.success("Senha alterada com sucesso!");
        setVisible(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch(() => {
        setLoading(false);
        message.info("Ocorreu um erro ao alterar senha");
      });
  }, [currentPassword, newPassword, confirmPassword]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Modal
      title="Trocar senha"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <DefaultButton loading={loading} onClick={handleChangePassword}>
          Alterar
        </DefaultButton>,
      ]}
    >
      <Input.Password
        placeholder="Senha atual"
        value={currentPassword}
        onChange={(event) => {
          setCurrentPassword(event.target.value);
        }}
        style={{ marginBottom: 10 }}
      />
      <Input.Password
        placeholder="Nova senha"
        value={newPassword}
        onChange={(event) => {
          setNewPassword(event.target.value);
        }}
        style={{ marginBottom: 10 }}
      />
      <Input.Password
        value={confirmPassword}
        onChange={(event) => {
          setConfirmPassword(event.target.value);
        }}
        placeholder="Confirme a senha"
      />
    </Modal>
  );
}

export default forwardRef(ChangePasswordModal);

import React from "react";

import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import SignIn from "../pages/SignIn";

export default function AuthRoutes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={SignIn} />
      </Switch>
    </Router>
  );
}

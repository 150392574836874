import React, { useEffect, useState } from "react";

import ContainerLayout from "../../components/ContainerLayout";
import { useAuth } from "../../contexts/auth";
import api from "../../services/api";
import { List, Card } from "antd";
import DefaultButton from "../../components/Button";
import { CameraOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [shifts, setShifts] = useState([]);

  const { user } = useAuth();

  const history = useHistory();

  useEffect(() => {
    async function getShifts() {
      try {
        if (user == null) return;
        const {
          data: { response },
        } = await api.get(`company/${user.employee.company_id}/shifts`);
        setShifts(response);
        setLoading(false);
      } catch (error) {
        setTimeout(() => getShifts(), 2000);
      }
    }

    getShifts();
  }, [user]);

  return (
    <ContainerLayout>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        dataSource={shifts}
        loading={loading}
        renderItem={(item) => (
          <List.Item>
            <Card title={item.week_day.description}>
              <p>Entrada ({item.hour_start})</p>
              <p>Saída ({item.hour_end})</p>
            </Card>
          </List.Item>
        )}
      />
      <DefaultButton
        icon={<CameraOutlined />}
        onClick={() => history.push("capture")}
        block
      >
        Bater ponto
      </DefaultButton>
    </ContainerLayout>
  );
}
